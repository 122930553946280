<template class="productionForm">
  <v-form ref="multiEditData" @submit.prevent="" :name="name">
    <v-row>
      <v-col sm="7">
        <p>{{ productionForm.animalTypes[animalTypeIndex]['animalType' + this.$getUpLangKey()] }}</p>
      </v-col>
    </v-row>
    <v-data-table
      v-show="isExpanded"
      ref="productionFormAnimalGroupTable"
      :headers="animalGroupRowFields()"
      :fields="animalGroupRowFields()"
      :items="animalGroupRows"
      :loading="loading"
      :hide-default-footer="true"
      density="compact"
    >
      <template v-slot:[`item.farmAgisIdParent`]="{ item }">
        <template v-if="item.farmAgisIdParent != item.farmAgisId">
          {{ item.farmAgisIdParent }}
        </template>
      </template>
      <template v-slot:[`item.quantity0`]="{ item }">
        <template v-if="item.productionFormRowId0">
          <div v-if="isEditable">
            <vNumberField
              @keyup="keyup"
              :name="'dairy' + dairyId + 'animalTypeIndx' + animalTypeIndex + 'item' + item.farmId + 'quantity0'"
              :rules="[$rules.isNumeric]"
              :precision="1"
              v-model="item.quantity0"
              filled
              class="right-aligned-input nomessage"
              @keyup.enter="$goNext($event.target)"
              density="compact"
              :id="'quantity0_' + item.farmAgisId"
              hide-details
            ></vNumberField>
          </div>
          <div v-else>
            {{ item.quantity0 }}
          </div>
        </template>
      </template>
      <template v-slot:[`item.status0`]="{ item }">
        <template v-if="item.status0">
          {{ statusName(item.status0) }}
        </template>
      </template>
      <template v-slot:[`item.quantity1`]="{ item }">
        <template v-if="item.productionFormRowId1">
          <div v-if="isEditable">
            <vNumberField
              @keyup="keyup"
              :name="'dairy' + dairyId + 'animalTypeIndx' + animalTypeIndex + 'item' + item.farmId + 'quantity1'"
              :rules="[$rules.isNumeric]"
              :precision="1"
              v-model="item.quantity1"
              filled
              class="right-aligned-input nomessage"
              @keyup.enter="$goNext($event.target)"
              density="compact"
              :id="'quantity1_' + item.farmAgisId"
              hide-details
            ></vNumberField>
          </div>
          <div v-else>
            {{ item.quantity1 }}
          </div>
        </template>
      </template>
      <template v-slot:[`item.status1`]="{ item }">
        <template v-if="item.status1">
          {{ statusName(item.status1) }}
        </template>
      </template>
      <template v-slot:[`item.quantity2`]="{ item }">
        <template v-if="item.productionFormRowId2">
          <div v-if="isEditable">
            <vNumberField
              @keyup="keyup"
              :name="'dairy' + dairyId + 'animalTypeIndx' + animalTypeIndex + 'item' + item.farmId + 'quantity2'"
              :rules="[$rules.isNumeric]"
              :precision="1"
              v-model="item.quantity2"
              filled
              class="right-aligned-input nomessage"
              @keyup.enter="$goNext($event.target)"
              density="compact"
              :id="'quantity2_' + item.farmAgisId"
              hide-details
            ></vNumberField>
          </div>
          <div v-else>
            {{ item.quantity2 }}
          </div>
        </template>
      </template>
      <template v-slot:[`item.status2`]="{ item }">
        <template v-if="item.status2">
          {{ statusName(item.status2) }}
        </template>
      </template>
      <template v-slot:[`item.quantity3`]="{ item }">
        <template v-if="item.productionFormRowId3">
          <div v-if="isEditable">
            <vNumberField
              @keyup="keyup"
              :name="'dairy' + dairyId + 'animalTypeIndx' + animalTypeIndex + 'item' + item.farmId + 'quantity3'"
              :rules="[$rules.isNumeric]"
              :precision="1"
              v-model="item.quantity3"
              filled
              class="right-aligned-input nomessage"
              @keyup.enter="$goNext($event.target)"
              density="compact"
              :id="'quantity3_' + item.farmAgisId"
              hide-details
            ></vNumberField>
          </div>
          <div v-else>
            {{ item.quantity3 }}
          </div>
        </template>
      </template>
      <template v-slot:[`item.status3`]="{ item }">
        <template v-if="item.status3">
          {{ statusName(item.status3) }}
        </template>
      </template>
      <template v-slot:[`item.quantity4`]="{ item }">
        <template v-if="item.productionFormRowId4">
          <div v-if="isEditable">
            <vNumberField
              @keyup="keyup"
              :name="'dairy' + dairyId + 'animalTypeIndx' + animalTypeIndex + 'item' + item.farmId + 'quantity4'"
              :rules="[$rules.isNumeric]"
              :precision="1"
              v-model="item.quantity4"
              filled
              class="right-aligned-input nomessage"
              @keyup.enter="$goNext($event.target)"
              density="compact"
              :id="'quantity4_' + item.farmAgisId"
              hide-details
            ></vNumberField>
          </div>
          <div v-else>
            {{ item.quantity4 }}
          </div>
        </template>
      </template>
      <template v-slot:[`item.status4`]="{ item }">
        <template v-if="item.status4">
          {{ statusName(item.status4) }}
        </template>
      </template>
      <template v-slot:[`item.quantity5`]="{ item }">
        <template v-if="item.productionFormRowId5">
          <div v-if="isEditable">
            <vNumberField
              @keyup="keyup"
              :name="'dairy' + dairyId + 'animalTypeIndx' + animalTypeIndex + 'item' + item.farmId + 'quantity5'"
              :rules="[$rules.isNumeric]"
              :precision="1"
              v-model="item.quantity5"
              filled
              class="right-aligned-input nomessage"
              @keyup.enter="$goNext($event.target)"
              density="compact"
              :id="'quantity5_' + item.farmAgisId"
              hide-details
            ></vNumberField>
          </div>
          <div v-else>
            {{ item.quantity5 }}
          </div>
        </template>
      </template>
      <template v-slot:[`item.status5`]="{ item }">
        <template v-if="item.status5">
          {{ statusName(item.status5) }}
        </template>
      </template>
      <template v-slot:[`item.quantity6`]="{ item }">
        <template v-if="item.productionFormRowId6">
          <div v-if="isEditable">
            <vNumberField
              @keyup="keyup"
              :name="'dairy' + dairyId + 'animalTypeIndx' + animalTypeIndex + 'item' + item.farmId + 'quantity6'"
              :rules="[$rules.isNumeric]"
              :precision="1"
              v-model="item.quantity6"
              filled
              class="right-aligned-input nomessage"
              @keyup.enter="$goNext($event.target)"
              density="compact"
              :id="'quantity6_' + item.farmAgisId"
              hide-details
            ></vNumberField>
          </div>
          <div v-else>
            {{ item.quantity6 }}
          </div>
        </template>
      </template>
      <template v-slot:[`item.status6`]="{ item }">
        <template v-if="item.status6">
          {{ statusName(item.status6) }}
        </template>
      </template>
      <template v-slot:[`item.quantity7`]="{ item }">
        <template v-if="item.productionFormRowId7">
          <div v-if="isEditable">
            <vNumberField
              @keyup="keyup"
              :name="'dairy' + dairyId + 'animalTypeIndx' + animalTypeIndex + 'item' + item.farmId + 'quantity7'"
              :rules="[$rules.isNumeric]"
              :precision="1"
              v-model="item.quantity7"
              filled
              class="right-aligned-input nomessage"
              @keyup.enter="$goNext($event.target)"
              density="compact"
              :id="'quantity7_' + item.farmAgisId"
              hide-details
            ></vNumberField>
          </div>
          <div v-else>
            {{ item.quantity7 }}
          </div>
        </template>
      </template>
      <template v-slot:[`item.status7`]="{ item }">
        <template v-if="item.status7">
          {{ statusName(item.status7) }}
        </template>
      </template>
      <template v-slot:[`item.quantity8`]="{ item }">
        <template v-if="item.productionFormRowId8">
          <div v-if="isEditable">
            <vNumberField
              @keyup="keyup"
              :name="'dairy' + dairyId + 'animalTypeIndx' + animalTypeIndex + 'item' + item.farmId + 'quantity8'"
              :rules="[$rules.isNumeric]"
              :precision="1"
              v-model="item.quantity8"
              filled
              class="right-aligned-input nomessage"
              @keyup.enter="$goNext($event.target)"
              density="compact"
              :id="'quantity8_' + item.farmAgisId"
              hide-details
            ></vNumberField>
          </div>
          <div v-else>
            {{ item.quantity8 }}
          </div>
        </template>
      </template>
      <template v-slot:[`item.status8`]="{ item }">
        <template v-if="item.status8">
          {{ statusName(item.status8) }}
        </template>
      </template>
      <template v-slot:[`item.quantity9`]="{ item }">
        <template v-if="item.productionFormRowId9">
          <div v-if="isEditable">
            <vNumberField
              @keyup="keyup"
              :name="'dairy' + dairyId + 'animalTypeIndx' + animalTypeIndex + 'item' + item.farmId + 'quantity9'"
              :rules="[$rules.isNumeric]"
              :precision="1"
              v-model="item.quantity9"
              filled
              class="right-aligned-input nomessage"
              @keyup.enter="$goNext($event.target)"
              density="compact"
              :id="'quantity9_' + item.farmAgisId"
              hide-details
            ></vNumberField>
          </div>
          <div v-else>
            {{ item.quantity9 }}
          </div>
        </template>
      </template>
      <template v-slot:[`item.status9`]="{ item }">
        <template v-if="item.status9">
          {{ statusName(item.status9) }}
        </template>
      </template>
      <template v-slot:[`item.quantity10`]="{ item }">
        <template v-if="item.productionFormRowId10">
          <div v-if="isEditable">
            <vNumberField
              @keyup="keyup"
              :name="'dairy' + dairyId + 'animalTypeIndx' + animalTypeIndex + 'item' + item.farmId + 'quantity10'"
              :rules="[$rules.isNumeric]"
              :precision="1"
              v-model="item.quantity10"
              filled
              class="right-aligned-input nomessage"
              @keyup.enter="$goNext($event.target)"
              density="compact"
              :id="'quantity10_' + item.farmAgisId"
              hide-details
            ></vNumberField>
          </div>
          <div v-else>
            {{ item.quantity10 }}
          </div>
        </template>
      </template>
      <template v-slot:[`item.status10`]="{ item }">
        <template v-if="item.status10">
          {{ statusName(item.status10) }}
        </template>
      </template>
      <template v-slot:[`item.quantity11`]="{ item }">
        <template v-if="item.productionFormRowId11">
          <div v-if="isEditable">
            <vNumberField
              @keyup="keyup"
              :name="'dairy' + dairyId + 'animalTypeIndx' + animalTypeIndex + 'item' + item.farmId + 'quantity11'"
              :rules="[$rules.isNumeric]"
              :precision="1"
              v-model="item.quantity11"
              filled
              class="right-aligned-input nomessage"
              @keyup.enter="$goNext($event.target)"
              density="compact"
              :id="'quantity11_' + item.farmAgisId"
              hide-details
            ></vNumberField>
          </div>
          <div v-else>
            {{ item.quantity11 }}
          </div>
        </template>
      </template>
      <template v-slot:[`item.status11`]="{ item }">
        <template v-if="item.status11">
          {{ statusName(item.status11) }}
        </template>
      </template>
      <!-- Footer -->
      <template v-slot:[`body.append`]="{ headers }">
        <tr>
          <td v-for="(header, i) in headers[0]" :key="i">
            <div v-if="header.value === 'farmAgisId'" class="text-begin">
              <span v-html="$t('production_form_multi_edit_table_footer_total')" />:<span class="dbm-number">
                {{ this.productionForm.animalTypes[this.animalTypeIndex].farmCount }}</span
              >
            </div>
            <div v-if="header.value === 'farmLocality'" class="text-begin">
              <span v-html="$t('production_form_multi_edit_table_footer_current_kg')" />
            </div>
            <div v-if="header.value === 'quantity0'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity0"> {{ $formatNumber(quantity0Total, 1) }}</span>
            </div>
            <div v-if="header.value === 'quantity1'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity1"> {{ $formatNumber(quantity1Total, 1) }}</span>
            </div>
            <div v-if="header.value === 'quantity2'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity2"> {{ $formatNumber(quantity2Total, 1) }}</span>
            </div>
            <div v-if="header.value === 'quantity3'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity3"> {{ $formatNumber(quantity3Total, 1) }}</span>
            </div>
            <div v-if="header.value === 'quantity4'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity4"> {{ $formatNumber(quantity4Total, 1) }}</span>
            </div>
            <div v-if="header.value === 'quantity5'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity5"> {{ $formatNumber(quantity5Total, 1) }}</span>
            </div>
            <div v-if="header.value === 'quantity6'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity6"> {{ $formatNumber(quantity6Total, 1) }}</span>
            </div>
            <div v-if="header.value === 'quantity7'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity7"> {{ $formatNumber(quantity7Total, 1) }}</span>
            </div>
            <div v-if="header.value === 'quantity8'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity8"> {{ $formatNumber(quantity8Total, 1) }}</span>
            </div>
            <div v-if="header.value === 'quantity9'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity9"> {{ $formatNumber(quantity9Total, 1) }}</span>
            </div>
            <div v-if="header.value === 'quantity10'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity10"> {{ $formatNumber(quantity10Total, 1) }}</span>
            </div>
            <div v-if="header.value === 'quantity11'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity11"> {{ $formatNumber(quantity11Total, 1) }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td v-for="(header, i) in headers[0]" :key="i">
            <div v-if="header.value === 'farmLocality'" class="text-begin">
              <span v-html="$t('production_form_multi_edit_table_footer_kg_before_correction')" />
            </div>
            <div v-if="header.value === 'quantity0'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity0">
                {{ $formatNumber(productionForm.animalTypes[animalTypeIndex].quantity0, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity1'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity1">
                {{ $formatNumber(productionForm.animalTypes[animalTypeIndex].quantity1, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity2'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity2">
                {{ $formatNumber(productionForm.animalTypes[animalTypeIndex].quantity2, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity3'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity3">
                {{ $formatNumber(productionForm.animalTypes[animalTypeIndex].quantity3, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity4'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity4">
                {{ $formatNumber(productionForm.animalTypes[animalTypeIndex].quantity4, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity5'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity5">
                {{ $formatNumber(productionForm.animalTypes[animalTypeIndex].quantity5, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity6'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity6">
                {{ $formatNumber(productionForm.animalTypes[animalTypeIndex].quantity6, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity7'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity7">
                {{ $formatNumber(productionForm.animalTypes[animalTypeIndex].quantity7, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity8'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity8">
                {{ $formatNumber(productionForm.animalTypes[animalTypeIndex].quantity8, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity9'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity9">
                {{ $formatNumber(productionForm.animalTypes[animalTypeIndex].quantity9, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity10'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity10">
                {{ $formatNumber(productionForm.animalTypes[animalTypeIndex].quantity10, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity11'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity11">
                {{ $formatNumber(productionForm.animalTypes[animalTypeIndex].quantity11, 1) }}</span
              >
            </div>
          </td>
        </tr>
        <tr>
          <td v-for="(header, i) in headers[0]" :key="i">
            <div v-if="header.value === 'farmLocality'" class="text-begin">
              <span v-html="$t('production_form_multi_edit_table_footer_difference')" />
            </div>
            <div v-if="header.value === 'quantity0'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity0">
                {{ $formatNumber(quantity0Total - productionForm.animalTypes[animalTypeIndex].quantity0, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity1'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity1">
                {{ $formatNumber(quantity1Total - productionForm.animalTypes[animalTypeIndex].quantity1, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity2'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity2">
                {{ $formatNumber(quantity2Total - productionForm.animalTypes[animalTypeIndex].quantity2, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity3'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity3">
                {{ $formatNumber(quantity3Total - productionForm.animalTypes[animalTypeIndex].quantity3, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity4'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity4">
                {{ $formatNumber(quantity4Total - productionForm.animalTypes[animalTypeIndex].quantity4, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity5'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity5">
                {{ $formatNumber(quantity5Total - productionForm.animalTypes[animalTypeIndex].quantity5, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity6'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity6">
                {{ $formatNumber(quantity6Total - productionForm.animalTypes[animalTypeIndex].quantity6, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity7'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity7">
                {{ $formatNumber(quantity7Total - productionForm.animalTypes[animalTypeIndex].quantity7, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity8'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity8">
                {{ $formatNumber(quantity8Total - productionForm.animalTypes[animalTypeIndex].quantity8, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity9'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity9">
                {{ $formatNumber(quantity9Total - productionForm.animalTypes[animalTypeIndex].quantity9, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity10'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity10">
                {{ $formatNumber(quantity10Total - productionForm.animalTypes[animalTypeIndex].quantity10, 1) }}</span
              >
            </div>
            <div v-if="header.value === 'quantity11'" class="text-end">
              <span class="dbm-number" v-if="productionForm.animalTypes[animalTypeIndex].quantity11">
                {{ $formatNumber(quantity11Total - productionForm.animalTypes[animalTypeIndex].quantity11, 1) }}</span
              >
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-form>
</template>

<script lang="ts">
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import vNumberField from '@/components/v-number-field.vue'
import { useProductionFormStatusStore } from '@/store/enums/ProductionFormStatusStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'mutiEditTable',
  components: {
    vNumberField: vNumberField
  },
  props: {
    modelValue: { type: Object, required: true },
    isExpanded: { type: Boolean, required: true },
    isEditable: { type: Boolean, required: true },
    animalTypeIndex: { type: Number, required: true },
    dairyId: { type: Number, required: true },
    name: { type: String, required: false }
  },
  data() {
    return {
      productionForm: { ...this.modelValue },
      animalGroupRows: [],
      numberOfMonths: String,
      loading: false,
      totalElements: 0,

      dialog: false,
      row: {},
      animalGroupTotalFromPatchResult: undefined,
      quantity0Total: 0,
      quantity1Total: 0,
      quantity2Total: 0,
      quantity3Total: 0,
      quantity4Total: 0,
      quantity5Total: 0,
      quantity6Total: 0,
      quantity7Total: 0,
      quantity8Total: 0,
      quantity9Total: 0,
      quantity10Total: 0,
      quantity11Total: 0,
      delta0: 0,
      delta1: 1,
      delta2: 2,
      delta3: 3,
      delta4: 4,
      delta5: 5,
      delta6: 6,
      delta7: 7,
      delta8: 8,
      delta9: 9,
      delta10: 10,
      delta11: 11
    }
  },
  emits: ['update:modelValue'],
  computed: {
    productionFormStatus() {
      return useProductionFormStatusStore().productionFormStatus
    }
  },
  methods: {
    statusName(monthStatus) {
      const statusName = this.productionFormStatus.find((status) => status.name === monthStatus)[this.$getLangKey()]
      return statusName
    },
    animalGroupRowFields() {
      const fields = [
        // AGIS-ID Betrieb
        {
          title: this.$t('production_form_multi_edit_table_agis_id_farm'),
          value: 'farmAgisId',
          align: 'left',
          sortable: true
        },
        // Betriebsform
        {
          title: this.$t('production_form_multi_edit_table_farm_type'),
          value: 'farmInfo',
          sortable: false
        },
        // Hierarchie
        {
          title: this.$t('production_form_multi_edit_table_hierarchy'),
          value: 'farmAgisIdParent',
          align: 'left',
          sortable: true
        },
        // Nr.
        {
          title: this.$t('production_form_multi_edit_table_nr'),
          value: 'farmDairyNo',
          align: 'left',
          sortable: true
        },
        //  Name
        {
          title: this.$t('production_form_multi_edit_table_family_name'),
          value: 'farmName1',
          align: 'left',
          sortable: true
        },
        // Vorname
        {
          title: this.$t('production_form_multi_edit_table_name'),
          value: 'farmName2',
          align: 'left',
          sortable: true
        },
        // PLZ
        {
          title: this.$t('production_form_multi_edit_table_zip'),
          value: 'farmZip',
          align: 'left',
          sortable: true
        },
        // Ort
        {
          title: this.$t('production_form_multi_edit_table_locality'),
          value: 'farmLocality',
          sortable: false
        }
      ]
      const validFromMonth = new Date(this.$route.query.validFrom).getMonth()
      const validToMonth = new Date(this.$route.query.validUntil).getMonth()
      const validFromYear = new Date(this.$route.query.validFrom).getFullYear()
      const validToYear = new Date(this.$route.query.validUntil).getFullYear()
      //logic for same year
      if (validFromYear == validToYear) {
        Array.from(Array(validToMonth + 1 - validFromMonth).keys()).forEach((index) => {
          const current = new Date(validFromYear, validFromMonth + index, 1)
          //console.log('current', current)
          // const translation = Intl.DateTimeFormat(this.$route.params.lang, { month: 'long' }).format(current)
          const currentMonth = current.getMonth() + 1
          const currentYear = current.getFullYear()
          let columnHeader = ''
          if (currentMonth < 10) {
            columnHeader = '0' + currentMonth + '.' + currentYear
          } else {
            columnHeader = currentMonth + '.' + currentYear
          }

          const quantityField = {
            title: columnHeader,
            value: `quantity${index}`,
            sortable: false,
            align: 'end'
          }
          const statusField = {
            title: this.$t('production_form_multi_edit_table_status'),
            value: `status${index}`,
            sortable: false,
            align: 'left'
          }
          fields.push(quantityField)
          fields.push(statusField)
        })
      }
      //logic for periods across two different years
      else {
        Array.from(Array(validToMonth + 13 - validFromMonth).keys()).forEach((index) => {
          let currentColumn = undefined
          // last months of "from year"
          if (validFromMonth + index > 12) {
            currentColumn = new Date(validToYear, (validFromMonth + index) % 12, 1)
          }
          // first months of "to year"
          else {
            currentColumn = new Date(validFromYear, validFromMonth + index, 1)
          }
          // const translation = Intl.DateTimeFormat(this.$route.params.lang, { month: 'long' }).format(current)
          const currentMonth = currentColumn.getMonth() + 1
          const currentYear = currentColumn.getFullYear()
          let columnHeader = ''
          if (currentMonth < 10) {
            columnHeader = '0' + currentMonth + '.' + currentYear
          } else {
            columnHeader = currentMonth + '.' + currentYear
          }

          const quantityField = {
            title: columnHeader,
            value: `quantity${index}`,
            sortable: false,
            align: 'end'
          }
          const statusField = {
            title: this.$t('production_form_multi_edit_table_status'),
            value: `status${index}`,
            sortable: false,
            align: 'left'
          }
          fields.push(quantityField)
          fields.push(statusField)
        })
      }
      return fields
    },
    setAllTotalQuantities() {
      for (let i = 0; i < 12; i++) {
        this.setDelta(i)
        this.setQuantityTotal(i)
      }
    },
    setQuantityTotal(columnIndex) {
      let total = this.productionForm.animalTypes[this.animalTypeIndex]['quantity' + columnIndex.toString()]
      total -= this['delta' + columnIndex.toString()]
      this['quantity' + columnIndex.toString() + 'Total'] = total
    },
    setDelta(columnIndex) {
      let delta = 0
      let total = 0
      let initialTotal = 0
      this.animalGroupRows.forEach((element) => {
        if (element['quantity' + columnIndex.toString()] != null) {
          if (typeof element['quantity' + columnIndex.toString()] == 'string') {
            total = total + parseInt(element['quantity' + columnIndex.toString()])
            if (element['initialQuantity' + columnIndex.toString()] != null) {
              initialTotal = initialTotal + parseInt(element['initialQuantity' + columnIndex.toString()])
            }
          } else {
            total = total + element['quantity' + columnIndex.toString()]
            initialTotal = initialTotal + element['initialQuantity' + columnIndex.toString()]
          }
        }
      })
      delta = initialTotal - total
      this['delta' + columnIndex.toString()] = delta
    },
    keyup() {
      this.$emit('update:modelValue', this.productionForm)
    }
  },
  watch: {
    modelValue(newValue) {
      this.productionForm = { ...this.modelValue }
      this.animalGroupRows = this.productionForm.animalTypes[this.animalTypeIndex].rows
    },
    productionForm: {
      handler(newValue) {
        //this.$emit('update:modelValue',this.productionForm)
      },
      deep: true
    },
    animalGroupRows: {
      handler(newValue) {
        this.setAllTotalQuantities()
      },
      deep: true
      //immediate: true
    }
  },
  beforeMount() {
    useProductionFormStatusStore().fetchProductionFormStatus()
  },
  mounted() {
    this.productionForm = { ...this.modelValue }
    this.animalGroupRows = this.productionForm.animalTypes[this.animalTypeIndex].rows
  }
})
</script>
