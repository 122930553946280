<template>
  <div class="container container-xl">
    <v-form ref="multiEditSearch" @submit.prevent="search()">
      <h1 v-html="$t('rebooking_form_read_title')"></h1>
      <v-row class="row search spacer-md">
        <v-col xs="12" sm="6" md="2">
          <search-legal-entity ref="search-legal-entity" @search="search" />
        </v-col>
        <v-col xs="12" sm="6" md="2">
          <search-farm ref="search-farm" @search="search" />
        </v-col>
        <v-col xs="12" sm="6" md="2" v-if="$privileges.has({ path: '/dairies', permission: 'search' })">
          <search-dairy ref="search-dairy" @search="search" />
        </v-col>
        <v-col xs="12" sm="6" md="2" v-else-if="$privileges.has({ path: '/dairies', permission: 'searchManaged' })">
          <search-dairy-managed ref="search-dairy-managed" @search="search" />
        </v-col>
        <v-col xs="12" sm="6" md="2">
          <search-contact ref="search-contact" @search="search" />
        </v-col>
        <v-col xs="12" sm="6" md="2">
          <search-rebooking-period ref="search-rebooking-period" @search="search" />
        </v-col>
      </v-row>
      <div>
        <v-btn color="primary" @click="search" :loading="loading"><span v-html="$t('rebooking_form_read_search')" /></v-btn>
        <v-btn color="secondary" @click="reset"><span v-html="$t('rebooking_form_read_reset')" /></v-btn>
      </div>
    </v-form>
    <div v-if="this.productionForms && this.productionForms.length > 0">
      <template v-if="!loading">
        <div v-for="productionForm in productionForms" :key="'pfid' + productionForm.dairyId">
          <multiEditPerDairy v-if="productionForm.dairyId" :productionForm="productionForm" />
          <div class="spacer">&nbsp;</div>
        </div>
      </template>
    </div>
    <div v-else>
      <v-col>
        <div class="mt-4" v-html="$t('rebooking_form_read_no_content')"></div>
      </v-col>
    </div>
  </div>
</template>
<script lang="ts">
import { apiURL } from '@/main'
import { Term } from '@/services/term'
import searchLegalEntity from '@/components/searchCards/searchLegalEntity.vue'
import { useSearchLegalEntityStore } from '@/store/SearchLegalEntityStore'
import searchFarm from '@/components/searchCards/searchFarm.vue'
import { useSearchFarmStore } from '@/store/SearchFarmStore'
import searchDairy from '@/components/searchCards/searchDairy.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import searchDairyManaged from '@/components/searchCards/searchDairyManaged.vue'
import { useSearchDairyManagedStore } from '@/store/SearchDairyManagedStore'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'
import searchRebookingPeriod from '@/components/searchCards/searchRebookingPeriod.vue'
import { showError } from '@/services/axios'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'
import rdhComponent from '@/components/rdh/rdhComponent'
import multiEditPerDairyVue from './multiEditPerDairy.vue'

import { useSearchProductionStore } from '@/store/SearchProductionStore'
import { useSearchRebookingPeriodStore } from '@/store/SearchRebookingPeriodStore'

// const defaultQuery = {
//   periodeYear: undefined,
//   periodeMonth: undefined,
//   loadPreviousMonths: undefined
// }

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'multiEditProductionForms',
  components: {
    searchLegalEntity,
    searchFarm,
    searchDairy,
    searchDairyManaged,
    searchContact,
    searchRebookingPeriod,
    DbmMonthPicker: DbmMonthPicker,
    rdhComponent: rdhComponent,
    multiEditPerDairy: multiEditPerDairyVue
  },
  computed: {
    searchProductionStore() {
      return useSearchProductionStore()
    },
    term(): any {
      return [
        ...Term.buildTermItems(useSearchLegalEntityStore(), useSearchLegalEntityStore().items),
        ...Term.buildTermItems(useSearchFarmStore(), useSearchFarmStore().items),
        ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items),
        ...Term.buildTermItems(useSearchDairyManagedStore(), useSearchDairyManagedStore().items),
        ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items)
      ]
    },
    params() {
      return {
        validFrom: useSearchRebookingPeriodStore().validFrom,
        validUntil: useSearchRebookingPeriodStore().validUntil,
        animalTypeId: useSearchRebookingPeriodStore().animalTypeId
      }
    },
    query(): any {
      return {
        term: Term.stringify(this.term),
        ...this.params,
        ...this.getJavaPageOptions()
      }
    }
  },
  data() {
    return {
      period: undefined,

      totalElements: 0,
      loading: false,
      downloadLoading: false,
      isExpanded: true,
      isEditable: false,
      productionForms: [],
      loadPreviousMonthsOptions: [
        {
          text: this.$t('production_form_read_display_current_month'),
          value: 'PREVIOUS'
        },
        {
          text: this.$t('production_form_read_display_start_of_year'),
          value: 'START_OF_THE_YEAR'
        },
        {
          text: this.$t('production_form_read_display_last_twelve_months'),
          value: 'TWELVE_MONTHS'
        }
      ]
    }
  },
  methods: {
    search() {
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch((e) => {
          this.load()
        })
    },
    reset() {
      this.$refs['search-legal-entity'].reset()
      this.$refs['search-farm'].reset()
      if (this.$refs['search-dairy']) this.$refs['search-dairy'].reset()
      if (this.$refs['search-dairy-managed']) this.$refs['search-dairy-managed'].reset()
      this.$refs['search-contact'].reset()
      this.$refs['search-rebooking-period'].reset()
      this.period = undefined
    },
    async load() {
      if (!(await this.$refs.multiEditSearch.validate()).valid) {
        return
      }

      this.loading = true
      try {
        this.productionForms = []
        const response = await this.axios.get(apiURL + '/productionForms/multiEdit', {
          params: this.query
        })
        const productionForms = response.data
        this.productionForms = Array.isArray(productionForms) ? productionForms : []
        this.productionForms.forEach((productionForm) => {
          productionForm.animalTypes.forEach((animalType) => {
            animalType.rows.forEach((row) => {
              for (let index = 0; index < 12; index++) {
                row['initialQuantity' + index] = row['quantity' + index]
              }
            })
          })
        })
      } catch (e) {
        showError(e)
        console.log('e', e)
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.searchProductionStore.loadPreviousMonths) {
        this.searchProductionStore.loadPreviousMonths = this.loadPreviousMonthsOptions[0].value
      }
      if (Object.keys(this.$route.query).length > 0) this.load()
    })
  },
  watch: {
    '$route.query': {
      handler(newValue) {
        if (Object.keys(this.$route.query).length > 0) this.load()
      },
      deep: true
    }
  }
})
</script>
