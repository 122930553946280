<template class="multiEditPerDairy">
  <div>
    <v-row>
      <v-col sm="7">
        <h1>
          {{ localProductionForm.dairyIdent }}
          {{ localProductionForm.dairyName1 }}
          {{ localProductionForm.dairyName2 }}
        </h1>
      </v-col>
      <v-col sm="4">
        <v-menu id="editendmenu" offset-y>
          <template v-slot:activator="{ props }">
            <v-btn id="production_form_multi_edit_table_edit_end" color="primary" v-bind="props" :loading="loading">
              <span v-html="$t('production_form_multi_edit_table_edit_end')" />
              <v-icon white right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              id="showEdit"
              key="editData"
              @click="
                () => {
                  isEditable = true
                  isExpanded = true
                }
              "
              v-if="!isEditable"
            >
              <v-list-item-title v-html="$t('production_form_multi_edit_table_edit')"></v-list-item-title>
            </v-list-item>
            <v-list-item id="saveData" key="saveData" @click="saveAll()" v-if="isEditable">
              <v-list-item-title v-html="$t('production_form_multi_edit_table_save')"></v-list-item-title>
            </v-list-item>
            <v-list-item id="abort" key="abortData" @click="reset()" v-if="isEditable">
              <v-list-item-title v-html="$t('production_form_multi_edit_table_abort')"></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col class="align-right" sm="1">
        <v-icon large @click="isExpanded = !isExpanded">{{ chevronClass }}</v-icon>
      </v-col>
    </v-row>
    <div v-for="(alert, index) in errorMessages" :key="'error' + index">
      <v-alert color="red" type="error" density="compact" dismissible>
        {{ alert }}
      </v-alert>
    </div>
    <div v-for="(animalGroup, index) in localProductionForm.animalTypes" :key="'animalg' + index">
      <multiEditTable
        :name="'pfid' + productionForm.id + 'animalg' + index"
        v-model="localProductionForm"
        :isExpanded="isExpanded"
        :isEditable="isEditable"
        :animalTypeIndex="index"
        :dairyId="productionForm.dairyId"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { apiURL } from '@/main'
import _ from 'lodash'
import { showError } from '@/services/axios'
import multiEditTable from './multiEditTable'
import { CustomFormatter } from '@/views/translations/CustomFormatter'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'multiEditPerDairy',
  components: { multiEditTable: multiEditTable },
  props: { productionForm: { type: Object, required: true } },
  data() {
    return {
      localProductionForm: {},
      isExpanded: true,
      isEditable: false,
      errorMessages: [],
      loading: false
    }
  },
  computed: {
    chevronClass() {
      return this.isExpanded ? 'mdi-chevron-down' : 'mdi-chevron-up'
    }
  },
  methods: {
    reset() {
      this.localProductionForm = JSON.parse(JSON.stringify(this.productionForm))
      this.isEditable = false
    },

    filterElements(original, local) {
      let result = {
        dairyId: original.dairyId,
        dairyIdent: original.dairyIdent,
        dairyName1: original.dairyName1,
        dairyName2: original.dairyName2,
        animalTypes: []
      }
      original.animalTypes.forEach((animal) => {
        let localAnimal = local.animalTypes.filter((locAnimal) => locAnimal.animalTypeName == animal.animalTypeName)[0]
        let resultAnimal = JSON.parse(JSON.stringify(localAnimal))
        resultAnimal.rows = []
        animal.rows.forEach((row) => {
          //compare
          let localRow = localAnimal.rows.filter((compRow) => compRow.farmId == row.farmId && compRow.farmAgisId == row.farmAgisId)[0]
          if (_.isEqual(row, localRow)) {
            return
          }
          let resultRow = { ...localRow }
          for (let i = 0; i < 12; i++) {
            /*
             //only keep differences
            if (row[`quantity${i}`] == localRow[`quantity${i}`]) {
              resultRow[`quantity${i}`] = null
              resultRow[`productionFormId${i}`] = null
              resultRow[`productionFormRowId${i}`] = null
            }
            */
            // remove frontend variables
            delete resultRow[`initialQuantity${i}`]
          }
          resultAnimal.rows.push(resultRow)
        })

        if (resultAnimal.rows.length > 0) {
          result.animalTypes.push(resultAnimal)
        }
      })
      return result
    },
    updateEditedFields(putResult) {
      putResult.animalTypes.forEach((animal) => {
        animal.rows.forEach((row) => {
          for (let i = 0; i < 12; i++) {
            if (row[`productionFormRowId${i}`] != null) {
              this.localProductionForm.animalTypes
                .filter((locAnimal) => locAnimal.animalTypeName == animal.animalTypeName)[0]
                .rows.filter((compRow) => compRow.farmId == row.farmId)[0][`quantity${i}`] = row[`quantity${i}`]
              this.localProductionForm.animalTypes
                .filter((locAnimal) => locAnimal.animalTypeName == animal.animalTypeName)[0]
                .rows.filter((compRow) => compRow.farmId == row.farmId)[0][`status${i}`] = row[`status${i}`]
              this.localProductionForm.animalTypes
                .filter((locAnimal) => locAnimal.animalTypeName == animal.animalTypeName)[0]
                .rows.filter((compRow) => compRow.farmId == row.farmId)[0][`productionFormRowId${i}`] = row[`productionFormRowId${i}`] // correction leads to new numbers
            }
          }
        })
      })
    },
    async saveAll() {
      this.loading = true
      this.isEditable = false

      let data = this.filterElements(this.productionForm, this.localProductionForm)

      this.errorMessages = []
      try {
        const result = await this.axios.put(apiURL + '/productionForms/multiEdit', data)
        //const result = await this.axios.put(apiURL + '/productionForms/updateMultiEdit', this.localProductionForm)
        if (await result) {
          this.updateEditedFields(result.data)
          if (result.data.problems.length > 0) {
            result.data.problems.forEach((problem) => {
              const translatedError = $t(problem.title, problem.placeholders)
              const formatter = new CustomFormatter()
              this.errorMessages.push(formatter.interpolate(translatedError, problem.placeholders) ?? problem.detail ?? problem.title)
            })
          }
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    }
  },
  watch: {},
  mounted() {
    this.localProductionForm = JSON.parse(JSON.stringify(this.productionForm))
  }
})
</script>
